/* src/App.css */

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  position: sticky;
  top: 0;
  background: #f1f1f1;
}

@media only screen and (max-width: 600px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead {
    display: none;
  }

  tr {
    margin-bottom: 15px;
  }

  td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}
.ag-theme-alpine {
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .ag-theme-alpine {
    font-size: 12px;
  }

  .ag-header-cell-label {
    display: block;
    white-space: normal;
  }
}
